import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Accordion from "react-bootstrap/Accordion"
import { Col, Form, Row } from "react-bootstrap"
import { CustomButton } from "../CustomButton"
import { useForm } from "react-hook-form"
import { InputField } from "./InputField"
import "./Filters.scss"
import { Route, Link, Routes, useLocation } from 'react-router-dom';

export const Filters = ({
  priceMin,
  priceMax,
  brands = [],
  productTypes = [],
  onSubmit = () => {},
  inStock,
  outOfStock,
  syncedProducts,
  onClickInStock = () => {},
  onClickOutOfStock = () => {},
  onClickProductSync = () => {},
  onChangeHandler = () => {},
  isLoading,
  setBrands = () => {},
  setProductTypes = () => {},
  showInStock
}) => {
  const formSchema = Yup.object().shape({
    price_min: Yup.string().matches(/^\d+$/, "Not valid").required(""),
    price_max: Yup.string().matches(/^\d+$/, "Not valid").required("")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm()

  const location = useLocation();
  const { hash, pathname, search } = location;

  const onChangeBrands = (e, name) => {
    const { checked } = e.target
    const update = [...brands]
    const index = update.findIndex(item => item.name === name)
    update[index].checked = checked
    setBrands(update)
    onChangeHandler({
      name: "vendor",
      value: name,
      checked
    })
  }
  
  const onChangeProductTypes = (e, name) => {
    const { checked } = e.target
    const update = [...productTypes]
    const index = update.findIndex(item => item.name === name)
    update[index].checked = checked
    setProductTypes(update)
    onChangeHandler({
      name: "product_type",
      value: name,
      checked
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="apply-btn-wrapper p-3">
          <Col xs="12">
            <h6 className="title-cost mb-0">Cost</h6>
          </Col>
          <Col xs="6">
            <InputField
              key="min"
              label=""
              name="price_min"
              defaultValue={priceMin}
              placeholder="Min"
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs="6">
            <InputField
              key="max"
              label=""
              name="price_max"
              defaultValue={priceMax}
              placeholder="Max"
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs="12" className="">
            <CustomButton
              label="Apply"
              variant="primary"
              backgroundColor="white"
              loading={isLoading}
            />
          </Col>
        </Row>
      </form>
      {showInStock && (
        <Row className="stock-status p-3">
          <Col xs="6" className="text-nowrap">
            <Form.Check
              key="in-stock"
              type="checkbox"
              id={`default-credit-card`}
              label="In-stock"
              checked={inStock}
              onChange={e => onClickInStock(e.target.checked)}
            />
          </Col>
          <Col xs="6" className="text-nowrap">
            <Form.Check
              key="out-of-stock"
              type="checkbox"
              id={`default-credit-card`}
              label="Out of Stock"
              checked={outOfStock}
              onChange={e => onClickOutOfStock(e.target.checked)}
            />
          </Col>
          {pathname == '/browse-products' ? <Col xs="6" className="text-nowrap">
            <Form.Check
              key="synced-products"
              type="checkbox"
              id={`default-credit-card`}
              label="Synced Products"
              checked={syncedProducts}
              onChange={e => onClickProductSync(e.target.checked)}
            />
          </Col>: null}
        </Row>
      )}
      <Accordion
        defaultActiveKey={["0", "1"]}
        alwaysOpen
        className="filters-accordion"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Brands</Accordion.Header>
          <Accordion.Body style={{ maxHeight: "30vh", overflowY: "auto" }}>
            {brands.map(({ name, count, checked }, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                id={`default-credit-card`}
                label={`${name} (${count})`}
                checked={checked}
                onChange={e => onChangeBrands(e, name)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Product Types</Accordion.Header>
          <Accordion.Body style={{ maxHeight: "30vh", overflowY: "auto" }}>
            {productTypes.map(({ name, count, checked }, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                id={`default-credit-card`}
                label={`${name} (${count})`}
                checked={checked}
                onChange={e => onChangeProductTypes(e, name)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}
