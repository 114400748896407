import parse from "html-react-parser"
import AuthCardImg from "../assets/images/auth_card_img.svg"
import LoginCardImg from "../assets/images/login_card_img.svg"
import SubscriptionCardImg from "../assets/images/subscription_card_img.svg"
import LogoImg from "../assets/images/logo.svg"
import SmokeSourceImg from "../assets/images/smoke_source.svg"
import BottleBongImg from "../assets/images/bottle-bong.svg"
import ShopifyImg from "../assets/images/shopify.svg"
import WoocommerceImg from "../assets/images/woocommerce.svg"
import UploadImg from "../assets/images/upload_image.svg"
import AdminPortalImg from "../assets/images/admin_portal_bg_img.svg"
// icons
import EyeClosedIcon from "../assets/icons/eye_closed.svg"
import EyeOpenIcon from "../assets/icons/eye_open.svg"
import BackArrowIcon from "../assets/icons/back_arrow.svg"
import DashboardIcon from "../assets/icons/dashboard.svg"
import ProductIcon from "../assets/icons/product.svg"
import SupportIcon from "../assets/icons/support.svg"
import CartIcon from "../assets/icons/cart.svg"
import UserIcon from "../assets/icons/user_icon.svg"
import LockIcon from "../assets/icons/lock.svg"
import CloseIcon from "../assets/icons/close.svg"
import DollarIcon from "../assets/icons/dollar.svg"
import OpenOrdersIcon from "../assets/icons/open_orders.svg"
import ProductAlertIcon from "../assets/icons/product_alert.svg"
import SearchIcon from "../assets/icons/search.svg"
import EditIcon from "../assets/icons/edit.svg"
import TermsIcon from "../assets/icons/terms.svg"
import PrivacyIcon from "../assets/icons/privacy.svg"
import VisaIcon from "../assets/icons/visa.svg"
import CheckIcon from "../assets/icons/check.svg"
import ErrorIcon from "../assets/icons/error.svg"
import ExpandIcon from "../assets/icons/expand.svg"
import FilterIcon from "../assets/icons/filter.svg"
import DashboardIconActive from "../assets/icons/dashboard_active.svg"
import ProductIconActive from "../assets/icons/product_active.svg"
import CartIconActive from "../assets/icons/cart_active.svg"
import DefaultProductIcon from "../assets/icons/default_product.svg"
import DeleteIcon from "../assets/icons/delete.svg"
import AddCircleIcon from "../assets/icons/add_circle_outline.svg"

export const assets = {
  AuthCardImg,
  LoginCardImg,
  SubscriptionCardImg,
  LogoImg,
  SmokeSourceImg,
  BottleBongImg,
  ShopifyImg,
  WoocommerceImg,
  UploadImg,
  AdminPortalImg,
  // icons
  EyeClosedIcon,
  EyeOpenIcon,
  BackArrowIcon,
  DashboardIcon,
  ProductIcon,
  CartIcon,
  SupportIcon,
  UserIcon,
  LockIcon,
  CloseIcon,
  DollarIcon,
  OpenOrdersIcon,
  ProductAlertIcon,
  SearchIcon,
  EditIcon,
  TermsIcon,
  PrivacyIcon,
  VisaIcon,
  CheckIcon,
  ErrorIcon,
  ExpandIcon,
  FilterIcon,
  DashboardIconActive,
  ProductIconActive,
  CartIconActive,
  DefaultProductIcon,
  DeleteIcon,
  AddCircleIcon
}

export const formatDateForDB = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return { year, month, day }
}

export const expiryDateFormat = expiryDate => {
  if (!expiryDate) {
    return
  }
  const splitDate = expiryDate.split("-")
  const year = splitDate[0]
  const month = splitDate[1]
  return `${month}/${year.slice(2, 4)}`
}

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
export const stringToHTML = str => {
  return parse(str)
}

export const getNextPage = url => {
  const _url = new URL(url)
  const params = new URLSearchParams(_url.search)
  return params.get("page")
}

export const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const getStockStatus = ({ inStock, outOfStock }) => {
  if ((inStock && outOfStock) || (!inStock && !outOfStock)) {
    return
  } else if (inStock) {
    return true
  } else if (outOfStock) {
    return false
  }
}

export const convertTZ = (date, tzString) => {
  const newDate = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString
    })
  )
  const { year, month, day } = formatDateForDB(newDate)
  return `${month}/${day}/${year}`
}

export const reduceMultiProperties = array => {
  const reducedArray = array.reduce(function (accumulator, item) {
    Object.keys(item).forEach(function (key) {
      if (
        key === "quantity" ||
        key === "price_total" ||
        key === "cost_price_total"
      ) {
        accumulator[key] = (accumulator[key] || 0) + item[key]
      }
    })

    return accumulator
  }, {})

  reducedArray.name = "Sub Total"
  return reducedArray
}

export const SUPPORT_URL = "https://smokesource.gorgias.help/en-US"
