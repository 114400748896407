import { useState } from "react"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { ContactInfo } from "./contactinfo"
import { Col, Row } from "react-bootstrap"
import { TimeZone } from "./timezone"
import { Feedback } from "./feedback"
import { Privacy, Terms } from "./terms"
import { SubscriptionDetails } from "./billing/subscriptionDetails"
import { PaymentDetails } from "./billing/paymentDetails"
import { Autopay } from "./billing/autopay"
import { useUserProfileContext } from "../../../contexts/UserProfileContext"
import { useEffect } from "react"
import { CustomSpinner } from "../../../components/CustomSpinner"
import "./accounttabs.scss"
import { StoreDetails } from "./storeDetails"
import {OtherStoreDetails} from "./OtherStoreDetails"
import {WoocommercePopUp} from "../../linkshopify/woocommercepopup"

export function AccountTabs() {
  const {
    isLoading,
    userProfile,
    getLoginUserProfile,
    timeZones,
    getTimezones,
    activeTab,
    wordpressConnect
  } = useUserProfileContext()
  const [key, setKey] = useState(activeTab)
  const [timezone, setTimeZone] = useState({
    label: "Select Timezone",
    value: ""
  })

  const [woocommerceClicked, setWoocommerceClicked] = useState(false)

  const [showWoocommerce, setShowWoocommerce] = useState(false)
  const [woocommerceUrl, setWoocommerceUrl] = useState("")
  const [woocommerceName, setWoocommerceName] = useState("")
  const onHideWoocommerce = () => setShowWoocommerce(false)
  const onClickHandleWoocommerce = () => {
      setShowWoocommerce(true)
  }

  useEffect(() => {
    if (
      userProfile?.user_profile?.id &&
      userProfile?.user_profile?.timezone &&
      timeZones.length
    ) {
      setTimeZone(
        timeZones.find(
          ({ value }) => value === userProfile.user_profile.timezone
        )
      )
    }
  }, [timeZones, userProfile])

  useEffect(() => {
    getLoginUserProfile()
    getTimezones()
    setKey(activeTab)
  }, [])

  const onConnect = (type) => {
    if (type === "woocommerce") {
      onClickHandleWoocommerce()
    }
  }

  const onClickWoocommerce = () => {
    wordpressConnect( woocommerceUrl,woocommerceName).then(response => {
      setWoocommerceClicked(true)
      const { store_url } = response
      const redirect_url = store_url
      window.open(redirect_url, "_blank").focus()
    })
  }
  const onRefresh = () => {
    window.location.reload()
  }
  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={k => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="account" title="account">
          <Row>
            <Col xs="12" md="6" lg="4" className="mb-3">
              {userProfile?.user_profile?.id && (
                <ContactInfo
                  user_profile={{
                    email: userProfile.email,
                    ...userProfile.user_profile
                  }}
                />
              )}
            </Col>
            <Col xs="12" md="6" lg="4" className="mb-3">
              {userProfile?.user_profile?.id && timeZones.length > 0 && (
                <TimeZone
                  user_profile={{
                    email: userProfile.email,
                    ...userProfile.user_profile
                  }}
                  timeZones={timeZones}
                />
              )}
              <div className="mb-3"></div>
              {(userProfile?.user_store?.url || userProfile?.other_stores) && (
                <StoreDetails url={userProfile?.user_store?.url} other_stores={userProfile?.other_stores} onConnect={onConnect}/>
              )}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="billing" title="billing">
          <Row>
            <Col xs="12" lg="4" xxl="3" className="mb-3">
              <SubscriptionDetails />
            </Col>
            <Col xs="12" lg="8" xxl="6" className="mb-3">
              <PaymentDetails />
              <div className="mb-4"></div>
              {/* <Autopay /> */}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="setting" title="setting">
          <Row>
            <Col xs="12" md="6" lg="4" className="mb-3">
              <Feedback />
            </Col>
            <Col xs="12" md="6" lg="3" className="mb-3">
              <Terms />
              <div className="mb-3"></div>
              <Privacy />
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <CustomSpinner loading={isLoading} />
      <WoocommercePopUp
        show={showWoocommerce}
        onHide={onHideWoocommerce}
        isLoading={isLoading}
        onClick={onClickWoocommerce}
        setWoocommerceUrl={setWoocommerceUrl}
        woocommerceUrl={woocommerceUrl}
        setWoocommerceName={setWoocommerceName}
        woocommerceName={woocommerceName}
        clicked={woocommerceClicked}
        onRefresh={onRefresh}
    />
    </>
  )
}
