import React from "react"
import { CustomModal } from "../../components/CustomModal"
import { Col, Row } from "react-bootstrap"
import { CustomButton } from "../../components/CustomButton"

export const WoocommercePopUp = ({ show, onHide, isLoading, onClick,setWoocommerceUrl,woocommerceUrl ,setWoocommerceName ,woocommerceName, clicked, onRefresh}) => {
  return (
    <CustomModal size="md" showHeader={true} show={show} onHide={onHide}>
      <Row className="p-0">
        <Col>
          <h5 className="title">
            Connect WooCommerce Store
          </h5>

          <p className="sub-title">
            {!clicked ? "Please enter your WooCommerce store URL to connect. You will be redirected to WooCommerce to authorize the connection." : "Please refresh the page once your WooCommerce store is connected."}
          </p>
        </Col>
      </Row>
      {!clicked ?  <Row className="add-card-payment justify-content-center">
        <Col xs="12" className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter your WooCommerce store name"
            value={woocommerceName}
            onChange={(e) => setWoocommerceName(e.target.value)}
          />
        </Col>
        <Col xs="8">
          <input
            type="text"
            className="form-control"
            placeholder="Enter your WooCommerce store URL"
            value={woocommerceUrl}
            onChange={(e) => setWoocommerceUrl(e.target.value)}
          />
        </Col>
        <Col xs="4">
          <CustomButton
            label="Connect"
            variant="primary"
            type="button"
            loading={isLoading}
            onClick={onClick}
          />
        </Col>
      </Row> : <Row className="add-card-payment justify-content-center">
        <Col xs="12">
          <CustomButton
            label="Refresh"
            variant="primary"
            type="button"
            loading={isLoading}
            onClick={onRefresh}
          />
        </Col>
      </Row>}
    </CustomModal>
  )
}
