import React, { useEffect, useState } from "react"
import { AuthContainer } from "../../common/AuthContainer"
import { Subscription } from "./subscription"
import { Col, Container, Row } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { CustomSpinner } from "../../components/CustomSpinner"
import { CustomModal } from "../../components/CustomModal"
import { PaymentForm } from "../../components/PaymentForm"
import { assets, formatDateForDB } from "../../utils"
import { useAuthContext } from "../../contexts/AuthContext"
import { toast } from "react-toastify"
export const Subscriptions = () => {
  const nav = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const onHide = () => {
    setShow(false)
    setTimeout(() => nav("/dashboard"), 0)
  }
  const [loader, setLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const [showPayment, setShowPayment] = useState(false)
  const onHidePayment = () => setShowPayment(false)

  const { notifySuccess, confirmPayment } = useAuthContext()
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  

  const {
    isLoading,
    userProfile,
    getLoginUserProfile,
    subscriptions,
    getSubscriptions,
    addSubscriptionRequest,
    addPaymentProfile,
    addPaymentSubscription
  } = useUserProfileContext()
  const onClickSubscription = id => {
    
    if (userProfile?.user_store?.id) {
      const { is_subscribed } = userProfile
      
      if (!is_subscribed) {
        const payload = { tier: id }
        
        return addSubscriptionRequest(payload).then(response => {
          const { is_subscribed, charge_response } = response

          if (charge_response?.confirmation_url) {
            window.location.href = charge_response.confirmation_url
          }

          if (!is_subscribed) {
            return nav("/subscription")
          }
          nav("/dashboard")
        })
      }
      nav("/dashboard")
    }else if(userProfile.other_stores?.length > 0){
      setSelectedSubscription(id)
      setShowPayment(true)
    }
  }

  useEffect(() => {
    if (searchParams.get("charge_id")) {
      setTimeout(() => {
        setLoader(false)
        getSubscriptions()
        getLoginUserProfile()
      }, 5000)
    } else {
      setLoader(false)
      getSubscriptions()
      getLoginUserProfile()
    }
  }, [])

  useEffect(() => {
    if (userProfile?.id && !loader) {
      console.log("userProfileSubscription", userProfile)
      const { is_subscribed } = userProfile
      if (is_subscribed && searchParams.get("charge_id")) {
        setTimeout(() => setShow(true), 0)
      } else if (is_subscribed) {
        setTimeout(() => nav("/dashboard"), 0)
      }
    }
  }, [userProfile, loader])


  const onSubmit = async vals => {
    const { card, expDate, cvv,name } = vals
    if (name && name.trim().split(" ").length >= 2) {
      const cardData = { cardNumber: "", month: "", year: "", cardCode: "" }

      const words = name.trim().split(" ");
      const firstName = words[0];
      const lastName = words.slice(1).join(" ");

      const { year, month } = formatDateForDB(expDate)
      cardData.cardNumber = card
      cardData.year = year
      cardData.month = month
      cardData.cardCode = cvv
      setLoading(true)
      confirmPayment(cardData)
      .then(response => {
        const { messages, opaqueData } = response
        if (messages.resultCode === "Ok") {
          addPaymentProfile(opaqueData)
          .then(() => {
            addPaymentSubscription({ charge_id: selectedSubscription, first_name:firstName, last_name:lastName })
            .then(() => {
              setShowPayment(false)
              setShow(true)
              nav("/dashboard")
              setLoading(false)
            }).catch((error) => {
              console.log(error);
              setLoading(false)
            })
          }).catch((error) => {
            console.log(error);
            setLoading(false)
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      })
      .then(() => {

      })
    }else{
      toast.error("Please enter a valid name")
    }
    
  }
  return (
    <AuthContainer className="subscriptions">
      <Container className="mt-3">
        <Row>
          {subscriptions.map((subscription, index) => (
            <Col xs={12} lg={3} className="mb-4" key={index}>
              <Subscription
                {...subscription}
                name={subscription.name}
                onClickSubscription={onClickSubscription}
                mt="mt-2"
              />
            </Col>
          ))}
          {subscriptions.length > 0 && (
            <Col className="text-center">
              <p>
                Are you a supplier? <Link to="/contact-us">Contact Us</Link> to
                learn more about your own branded retailer portal!
              </p>
            </Col>
          )}
        </Row>
      </Container>
      <CustomModal
        size="md"
        headerTitle="Success"
        showHeader={true}
        footerBtnText="Ok"
        showFooter={true}
        show={show}
        onHide={onHide}
      >
        <p>
          Your transaction is successfull! Please click ok to go to
          dashboard.
        </p>
      </CustomModal>
      <CustomModal size="md" show={showPayment} onHide={onHidePayment}>
          <Row>
                <Col>
                  <h5 className="title">
                     Subscription Payment
                  </h5>
                  <p className="sub-title">Please enter your card details</p>
                </Col>
          </Row>
          <PaymentForm
                addCard={true}
                isLoading={loading}
                onCancel={onHidePayment}
                onSubmit={onSubmit}
          />
      </CustomModal>
      <CustomSpinner loading={isLoading || loader} />
    </AuthContainer>
  )
}
