import React, { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useAuthContext } from "../../contexts/AuthContext"
import { AuthContainer } from "../../common/AuthContainer"
import { InputField } from "../../components/InputField"
import { useForm } from "react-hook-form"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { InputFieldPassword } from "../../components/InputFieldPassword"
import { assets } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { CustomSpinner } from "../../components/CustomSpinner"
import { ShopURL } from "./shopUrl"

export const Login = () => {
  const nav = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { isLoading, notifySuccess, userLogin, authToken, loginWithShopify } =
    useAuthContext()
  const formSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at 6 char long")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm(formOptions)
  const onSubmit = vals => {
    userLogin(vals).then(authFlow)
  }

  useEffect(() => {
    if (authToken) {
      nav("/dashboard")
    }
  }, [])

  useEffect(() => {
    if (searchParams.size) {
      const config = { params: {} }
      for (const [key, value] of searchParams.entries()) {
        config.params[key] = value
      }
      loginWithShopify(config).then(authFlow)
    }
  }, [])

  const authFlow = user => {
    const { user_store, is_subscribed, is_payment_profile_exists, is_superuser, other_stores } = user
    if (is_superuser) {
      notifySuccess("You have logged in successfully!")
      setTimeout(() => nav("/users"), 300)
    } else if (!user_store?.id && other_stores.length === 0) {
      setTimeout(() => nav("/link-shopify-app"), 300)
    } else if (!is_subscribed && !is_payment_profile_exists) {
      setTimeout(() => nav("/subscription"), 300)
    } else {
      notifySuccess("You have logged in successfully!")
      setTimeout(() => nav("/dashboard"), 300)
    }
  }

  const renderFormData = () => {
    return formData.map(({ label, name, type, placeholder, rules }, index) => {
      if (type === "password") {
        return (
          <InputFieldPassword
            key={index}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
            control={control}
            trigger={trigger}
            rules={rules}
          />
        )
      }
      return (
        <InputField
          key={index}
          label={label}
          name={name}
          type={type}
          placeholder={placeholder}
          control={control}
          trigger={trigger}
          rules={rules}
        />
      )
    })
  }

  return (
    <>
      {searchParams.size > 0 ? (
        <AuthContainer className="shopifyLogin">
          <CustomSpinner />
        </AuthContainer>
      ) : (
        <AuthContainer title="Sign In" className="login">
          <div className="signup-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderFormData()}
              <Row>
                <Col xs="12" className="d-flex align-items-center">
                  <img src={assets.LockIcon} />
                  <p className="mb-0 ms-2">
                    <Link to="/forget-password" style={forgetPassTextStyels}>
                      Forgot password
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-5">
                <Col xs="11" className="mb-3">
                  <CustomButton
                    label="Sign In"
                    variant="primary"
                    type="submit"
                    loading={isLoading}
                  />
                </Col>
                <Col className="text-center">
                  <p>
                    Don't have an account?{" "}
                    <Link to="/signup">Create Account</Link>
                  </p>
                </Col>
              </Row>
            </form>
          </div>
          <ShopURL />
        </AuthContainer>
      )}
    </>
  )
}

const forgetPassTextStyels = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  color: "#444444"
}
