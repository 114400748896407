import React from "react";
import { useForm } from "react-hook-form";
import { CustomCard } from "../../../components/CustomCard";
import { Col, Row, Form, Button } from "react-bootstrap";
import { InputField } from "../../../components/InputField";

const storeType = {
  woocommerce: "WordPress WooCommerce"
};

export const StoreDetails = ({ url, other_stores, onConnect }) => {
  const { control, trigger } = useForm();

  return (
    <CustomCard title="Store Details">
       {url ? <Row>
          <Col>
            <Form.Group className="mb-3" controlId="shopify-url">
              <InputField
                label="Shopify URL"
                name="url"
                value={url}
                control={control}
                trigger={trigger}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>: null}
        <Row> 
        {Object.keys(storeType).map((type) => {
          const store = other_stores.find((s) => s.store_type === type);
          return (
            <Col key={type}>
              <Form.Group className="mb-3" controlId={`${type}-input`}>
                {store ? (
                  <InputField
                    label={storeType[type]}
                    name={`${type}-url`}
                    value={store.url}
                    control={control}
                    trigger={trigger}
                    disabled={true}
                  />
                ) : (
                  <Button style={{width:'100%'}} variant="primary" onClick={() => onConnect(type)}>
                    Connect {storeType[type]}
                  </Button>
                )}
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </CustomCard>
  );
};
